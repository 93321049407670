import React, { useState, useEffect } from 'react';

const Intro = ({ location, handleGameStart }) => {
    const [story, setStory] = useState('');
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchStory = async (retryCount = 0) => {
        if (!location) return;

        try {
            const response = await fetch(`https://escapecitygames.pellenilsen.no/api/stories/${location}`);
            const data = await response.json();

            if (data.success && data.data.length > 0) {
                const formattedStory = data.data[0].story.replace(/\/n/g, '<br />');
                setStory(formattedStory);
                setTitle(data.data[0].title);
                setIsLoading(false);
            } else {
                throw new Error('No story found');
            }
        } catch (error) {
            console.error('Error fetching story:', error);
            if (retryCount < 2) {  // Retry up to 2 times
                setStory('Failed to fetch story. Trying again...');
                setTimeout(() => fetchStory(retryCount + 1), 1000);  // Retry after 1 second
            } else {
                setStory('Failed to fetch story. Refreshing page...');
                setTimeout(() => window.location.reload(), 2000);  // Refresh after 2 seconds
            }
        }
    };

    useEffect(() => {
        fetchStory();
    }, [location]);

    return (
        <div className="fixed overflow-scroll z-[9999] bg-white/90 w-full h-full p-8">
            <h1 className="text-2xl font-bold">{title}</h1>

            {isLoading ? (
                <p className="mt-4">Laster... Vennligst vent</p>
            ) : (
                <div className='mt-4' dangerouslySetInnerHTML={{ __html: story }}></div>
            )}

            <button
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center"
                onClick={(e) => handleGameStart()}
                disabled={isLoading}
            >
                {location.includes('eng') ? 'Play' : 'Start spill!'}
            </button>
        </div>
    );
};

export default Intro;
