import React from 'react';

const Lose = () => {
    return (
        <div className="fixed overflow-scroll z-[9999] bg-white/90 w-full h-full p-8">
            <h1 className="text-2xl font-bold">Beklager</h1>

            <p className="mt-4">
                Dere klarte desverre ikke finne løsnings-setningen, og svenskene fant dere.
            </p>

            <p className="mt-4">
                BAM! Celledøra smalt igjen og Christian Hals blir nå satt bak lås og slå av svenskene igjen..
            </p>

            <p className="mt-4">
                Den hemmelige setningen dere var ute etter var "utgang er
                vaterland". Christian er skuffet over resultatet, men setter pris på forsøket dere ga.
            </p>

            <a href='https://escapecitygame.no' className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center">
                Avslutt spill
            </a>
        </div>
    );
};

export default Lose;
