import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Stats = () => {
    const navigate = useNavigate();
    const [finishes, setFinishes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFinishes = async () => {
            try {
                const response = await fetch('https://escapecitygames.pellenilsen.no/api/get-finishes');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.success) {
                    setFinishes(data.data);
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                setError('Failed to fetch data: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFinishes();
    }, []);

    const formatTime = (time) => {
        // Check if time is in minutes (like "1.48")
        if (time.includes('.')) {
            const [minutes, fractionalMinutes] = time.split('.');
            const seconds = Math.round(fractionalMinutes * 0.6);
            return `${minutes.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
        // Assume it's already in HH:MM:SS format
        return time;
    };

    return (
        <div>
            <section id='hero'>
                <div
                    className='w-full flex justify-between'
                    onClick={() => navigate('/')}
                >
                    <img
                        src='https://escapecitygame.no/content/confetti.png'
                        alt='confetti'
                        className='absolute'
                    />
                    <div className='flex h-16'>
                        <img
                            src='https://escapecitygame.no/content/logo.png'
                            alt='logo'
                        />
                        <h1 className='my-auto h-min font-primary text-[16px] font-semibold uppercase'>
                            Escape City Game
                        </h1>
                    </div>
                </div>
            </section>

            <div className='p-4'>
                {isLoading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className='text-red-500'>{error}</p>
                ) : (
                    <table className='table-auto text-left w-full border-collapse'>
                        <thead>
                            <tr>
                                <th className='border px-4 py-2'>Navn</th>
                                <th className='border px-4 py-2'>Tid</th>
                                <th className='border px-4 py-2'>Riktige svar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {finishes.map((finish) => (
                                <tr key={finish.id}>
                                    <td className='border px-4 py-2'>{finish.name}</td>
                                    <td className='border px-4 py-2'>{formatTime(finish.time)}</td>
                                    <td className='border px-4 py-2'>{finish.correct}/19</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default Stats;