import React from 'react';
import { useState } from 'react';

const Backpack = ({
    secretWordDisplay,
    setNotificationText,
    endScreen,
    secretWord,
    handleSubmitSecretWord,
    userInput,
    setUserInput,
    backpackVisible,
    setBackpackVisible,
    location
}) => {
    const handleButtonClick = () => {
        setBackpackVisible((prevVisible) => !prevVisible);
        setNotificationText('');
    };

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    return (
        <>
            <div
                className="fixed bottom-5 left-5 z-[998] bg-white h-20 w-20 rounded-full p-4 shadow"
                onClick={handleButtonClick}
            >
                <img
                    src="/assets/bag.png"
                    alt="backpack"
                    className="w-auto h-auto"
                />
            </div>
            {backpackVisible && (
                <div className="absolute z-[999] bg-white/90 w-full h-full p-8 flex flex-col justify-center items-center">
                    <h1 className="text-2xl font-bold mb-4">{location.includes('eng') ? 'Backpack' : 'Ryggsekk'}</h1>
                    <h2 className="text-xl font-semibold tracking-widest">
                        {secretWordDisplay}
                    </h2>
                    {endScreen && (
                        <>
                            <input
                                type="text"
                                value={userInput}
                                onChange={handleInputChange}
                                placeholder="Enter the secret word"
                                className="mt-4 p-2 border border-gray-400 rounded"
                            />
                            <button
                                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleSubmitSecretWord}
                            >
                                {location.includes('eng') ? 'Guess' : 'Gjett'}
                            </button>
                            <p className="mt-2 space-x-20 text-center italic">
                                
                                {location.includes('eng') ? 'You can only guess ONCE! Think hard about this...' : 'Du kan bare gjette 1 gang! Tenk deg godt om...'}
                            </p>
                        </>
                    )}
                    <button
                        className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center"
                        onClick={() => setBackpackVisible(!backpackVisible)}
                    >
                        {location.includes('eng') ? 'Close backpack' : 'Lukk ryggsekken'}
                    </button>
                </div>
            )}
        </>
    );
};

export default Backpack;
