import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const stripePromise = loadStripe(
    'pk_live_51N00dYDvIq8Qvkh2mV7yQ4JCe2WjW4ehapMs3WR5a6DWLcOEknw32x2oZUs83hzbCZo0tKXbArYDj3VQYhLez8SX00fGuyIpQB'
);

const TestPurchase = () => {
    const navigate = useNavigate();

    return (
        <div className='m-0 p-0'>
            <section id='hero'>
                <div className='w-full flex justify-between' onClick={() => navigate('/')}>
                    <img src='https://escapecitygame.no/content/confetti.png' alt='confetti' className='absolute z-[99]' />
                    <div className='flex h-16'>
                        <img src='https://escapecitygame.no/content/logo.png' alt='logo' />
                        <h1 className='my-auto h-min font-primary text-[16px] font-semibold uppercase'>Escape City Game</h1>
                    </div>
                    <div>
                    </div>
                </div>
            </section>

            <section id='intro' className='px-4 mt-16 text-center'>
                <h2 className='font-primary text-2xl'>Kjøp spill kode</h2>
                <p className='font-primary mt-0 font-light text-xl'>Med en kode kan du starte rømnings eventyret ditt!</p>
            </section>

            <section id='funksjon' className='bg-[#F9F7F7] mt-12 p-6 shadow-[inset_0_0_8px_rgba(0,0,0,0.6)] text-center z-[999]'>
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </section>
        </div>
    )
}

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState('Gamlebyen');
    const [paymentStatus, setPaymentStatus] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                email: email,
            },
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        } else {
            console.log(paymentMethod);
            fetch(
                'https://escapecitygames.pellenilsen.no/api/payments/create-payment-intent',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentMethodId: paymentMethod.id,
                        email: email,
                        name: name,
                        location: location,
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    if (data.success) {
                        setPaymentStatus('success');
                        setCode(data.code);
                        document.getElementById('payButton').disabled = true;
                        try {
                            emailjs.send(
                                'service_z3lrs6o',
                                'template_n6g3fce',
                                {
                                    to_name: name,
                                    code: data.code,
                                    to_email: email,
                                },
                                'Hw9ak6bCmBcpNx9UM'
                            );
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        setPaymentStatus('fail');
                        setError(data.message);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setPaymentStatus('fail');
                    setError('Payment failed. Please try again.');
                    console.error('Payment error:', error);
                });
        }
    };

    const renderButtonContent = () => {
        if (loading) {
            return <span>Kjøper...</span>;
        } else if (paymentStatus === 'success') {
            return <span>&#10003;</span>;
        } else if (paymentStatus === 'fail') {
            return <span>&#10060;</span>;
        }
        return 'Bekreft betaling';
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="px-4 py-5 text-left">
                <label htmlFor="name" className='text-lg font-light mb-2'>Navnet på kortet</label>
                <input
                    type="text"
                    value={name}
                    name='name'
                    onChange={(e) => setName(e.target.value)}
                    className="block p-4 border w-full border-black focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mb-6"
                    placeholder="Ditt fulle navn"
                    required
                />

                <label htmlFor="email" className='text-lg font-light mb-2'>Din e-post</label>
                <input
                    type="email"
                    value={email}
                    name='email'
                    onChange={(e) => setEmail(e.target.value)}
                    className="block p-4 border w-full border-black focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mb-6"
                    placeholder="Enter your email"
                    required
                />

                <label htmlFor="location" className='text-lg font-light mb-2'>Spillested</label>
                <select
                    value={location}
                    name='location'
                    onChange={(e) => setLocation(e.target.value)}
                    className="block p-4 border w-full border-black focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm"
                >
                    <option value="Gamlebyen">Gamlebyen</option>
                    <option value="Gamlebyen_eng">Gamlebyen - English</option>
                </select>

                <div className="text-[#596BA4] text-lg text-left font-bold mt-6">
                    Kr 200,-
                </div>
                <CardElement className="block p-4 border w-full bg-white border-black focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mb-6" />
                <button
                    type="submit"
                    id='payButton'
                    disabled={!stripe}
                    className="mt-2 w-full px-4 py-3 bg-gradient-to-r from-[#AAC4FC] to-[#98D1AB] text-lg text-white font-button disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {renderButtonContent()}
                </button>
                {code && (
                    <>
                        <div className="text-md mt-2 font-primary">Koden din er {code}</div>
                        <div className="text-md mt-2 font-primary">Takk for kjøpet! Du vil straks få koden tilsendt til din e-post adresse.</div>
                    </>
                )}
                {error && (
                    <div className="text-red-500 text-sm mt-2">{error}</div>
                )}
            </form>
        </>
    );
};

export default TestPurchase