import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(
    'pk_live_51N00dYDvIq8Qvkh2mV7yQ4JCe2WjW4ehapMs3WR5a6DWLcOEknw32x2oZUs83hzbCZo0tKXbArYDj3VQYhLez8SX00fGuyIpQB'
);
// const stripePromise = loadStripe('pk_live_51N00dYDvIq8Qvkh2mV7yQ4JCe2WjW4ehapMs3WR5a6DWLcOEknw32x2oZUs83hzbCZo0tKXbArYDj3VQYhLez8SX00fGuyIpQB');

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState('Gamlebyen');
    const [paymentStatus, setPaymentStatus] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                email: email,
            },
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        } else {
            console.log(paymentMethod.id);
            fetch(
                'https://escapecitygames.pellenilsen.no/api/payments/create-payment-intent',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentMethodId: paymentMethod.id,
                        email: email,
                        name: name,
                        location: location,
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    if (data.success) {
                        setPaymentStatus('success');
                        setCode(data.code);
                    } else {
                        setPaymentStatus('fail');
                    }
                })
                .catch(() => {
                    setLoading(false);
                    setPaymentStatus('fail');
                });
        }
    };

    const renderButtonContent = () => {
        if (loading) {
            return <span>Purchasing...</span>;
        } else if (paymentStatus === 'success') {
            return <span>&#10003;</span>;
        } else if (paymentStatus === 'fail') {
            return <span>&#10060;</span>;
        }
        return 'Pay';
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="px-4 py-5">
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm"
                    placeholder="Enter your full name"
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mt-6"
                    placeholder="Enter your email"
                    required
                />
                <select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm mt-6"
                >
                    <option value="Gamlebyen">Gamlebyen</option>
                </select>
                <div className="text-blue-500 text-lg text-left font-bold mt-6">
                    Kr 200,-
                </div>
                <CardElement className="block w-full p-4 text-lg border border-gray-300 rounded-md shadow-sm mt-0" />
                <button
                    type="submit"
                    disabled={!stripe}
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center"
                >
                    {renderButtonContent()}
                </button>
                {code && (
                    <div className="text-green-500 text-xl mt-2">{code}</div>
                )}
                {error && (
                    <div className="text-red-500 text-sm mt-2">{error}</div>
                )}
            </form>
            <a
                href="https://escapecitygame.no"
                className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded text-lg mt-2 text-center"
            >
                Back
            </a>
        </>
    );
};

const Purchase = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
                Purchase Access
            </h1>
            <p className="text-gray-600 mb-2">
                Enter your payment details below:
            </p>
            <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 w-full max-w-md">
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </div>
        </div>
    );
};

export default Purchase;
