import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessPage } from '../CONSTANTS/CONSTANTS';

const AccessPage = () => {
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleCodeVerification = async () => {
        if (code.length !== 5) {
            setMessage('Please enter a valid 5-digit code.');
            return;
        }

        try {
            const response = await fetch(`https://escapecitygames.pellenilsen.no/api/check-code/${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setMessage(`Welcome, the code belongs to: ${data.customerName}`);
                navigate(`/game?code=${code}&location=${data.location}`);
            } else {
                const errorData = await response.json();
                setMessage(errorData.message || 'Error verifying code.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to connect to the server.');
        }
    };

    return (
        <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center'>
            <h1 className='text-3xl font-bold text-gray-800 mb-4'>{accessPage.Title}</h1>
            <p className='text-gray-600 mb-2'>{accessPage.BuyCode}</p>
            <p className='text-gray-600 mb-2'>{accessPage.Disconnected}</p>
            <a href={accessPage.BuyCodeLink.link} className='text-blue-500 hover:text-blue-700 mb-4'>{accessPage.BuyCodeLink.content}</a>
            <div className='bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4 space-y-4'>
                <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className='block p-4 border w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md shadow-sm'
                    placeholder={accessPage.InputPlaceholder}
                />
                <button
                    onClick={handleCodeVerification}
                    className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded text-lg'
                >
                    {accessPage.StartButton}
                </button>
                {message && <p className="mt-4 text-red-500">{message}</p>}
            </div>

            <p>Kundeservice: <a href="mailto:support@escapecitygame.no" className='text-blue-700 hover:underline'>support@escapecitygame.no</a></p>
        </div>
    )
}

export default AccessPage