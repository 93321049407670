import React from 'react';
import MapComponent from '../components/MapComponent';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const GameMap = () => {
    const react_location = useLocation();
    const { code, location } = queryString.parse(react_location.search);

    return (
        <MapComponent code={code} location={location} />
    )
}

export default GameMap