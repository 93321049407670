import React from 'react';

const AfterQuestionModal = ({ text, handleClose, location }) => {

    const formattedAfterQuestion = text.replace(/\/n/g, '<br />');

    return (
        <>
            {text.length > 0 && (
                <div className="absolute z-[1000] bg-white w-full h-full p-8 flex flex-col justify-center items-center" onClick={handleClose}>
                    <div className="bg-white p-4 rounded text-center h-auto mt-auto mb-auto">
                        <div dangerouslySetInnerHTML={{ __html: formattedAfterQuestion }}></div>
                        <p className='mt-2 text-center italic text-sm'>{location.includes('eng') ? 'Click to close' : 'Klikk for å lukke'}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default AfterQuestionModal;
