import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccessPage from './pages/AccessPage';
import GameMap from './pages/GameMap';
import Purchase from './pages/Purchase';
import TestHome from './pages/TestHome';
import TestPurchase from './pages/TestPurchase';
import Vilkår from './pages/Vilkår';
import Stats from './pages/Stats';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/test" element={<AccessPage />} />
                <Route path="/game" element={<GameMap />} />
                <Route path="/purchase" element={<TestPurchase />} />
                <Route path="/" element={<TestHome />} />
                <Route path="/purchases" element={<Purchase />} />
                <Route path="/vilkår" element={<Vilkår />} />
                <Route path="/leaderboard" element={<Stats />} />
            </Routes>
        </Router>
    );
}

export default App;
