import React from 'react'
import { useNavigate } from 'react-router-dom'

const Vilkår = () => {

    const navigate = useNavigate();

    return (
        <>
            <section id='hero'>
                <div className='w-full flex justify-between' onClick={() => navigate('/')}>
                    <img src='https://escapecitygame.no/content/confetti.png' alt='confetti' className='absolute' />
                    <div className='flex h-16'>
                        <img src='https://escapecitygame.no/content/logo.png' alt='logo' />
                        <h1 className='my-auto h-min font-primary text-[16px] font-semibold uppercase'>Escape City Game</h1>
                    </div>
                    <div>
                        {/* <svg width="64" height="64" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="12" y1="25" x2="38" y2="25" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                            <line x1="12" y1="16" x2="38" y2="16" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                            <line x1="12" y1="34" x2="38" y2="34" stroke="#1D1D1D" strokeWidth="4" strokeLinecap="round"/>
                        </svg> */}
                    </div>
                </div>
            </section>
            <div className='m-0 p-10'>
                <h1 className='font-bold text-3xl mb-4'>Vilkår og Betingelser til EscapeCityGame</h1>

                <h2 className='font-semibold text-xl mb-4'>Partene</h2>
                <p className='mb-2'>Selger</p>
                <p className='mb-2'>Firmanavn: EscapeCityGame</p>
                <p className='mb-2'>E-Post: <a href="mailto:support@escapecitygame.no" className='text-blue-500 underline'>support@escapecitygame.no</a></p>
                <p className='mb-2'>Organisasjonsnummer: 933482847</p>
                <p className='mb-6'>Kjøper er den personen som foretar bestillingen.</p>

                <h2 className='font-semibold text-xl mb-4'>Avtaleinngåelse</h2>
                <p className='mb-6'>Avtalen er bindene for begge parter når kjøperens bestilling er mottatt av oss. En part er likevel ikke bundet av avtalen dersom det er forekommet skrive eller tastefeil ved inntasting ved et kjøp.</p>

                <h2 className='font-semibold text-xl mb-4'>Ordrebekreftelse</h2>
                <p className='mb-6'>Når vi har mottatt din bestilling, vil vi sende uten ugrunnet opphold bekrefte ordren ved å sende over en ordrebekreftelse til deg. Det anbefales av deg som kjøper og se over ordrebekreftelsen, og se over at du har kjøpt riktig sted på spillet og valgt riktig språk. Oppdager du en feil på ordrebekreftelsen som ikke samsvarer med hva du har bestilt, ta kontakt med oss så fort som mulig. E-post: <a href="mailto:support@escapecitygame.no" className='text-blue-500 underline'>support@escapecitygame.no</a>.</p>

                <h2 className='font-semibold text-xl mb-4'>Levering</h2>
                <p className='mb-6'>Levering av produktet vil du som kjøper få overlevert på e-post. Kjøper vil få en e-post der det medfølger en kode. En kode som skal brukes for å komme inn på spillet. Dette skal skje så fort kjøperen har utført betaling.</p>

                <h2 className='font-semibold text-xl mb-4'>Angrerett</h2>
                <p className='mb-2'>Kjøper kan angre kjøpet av produktet etter angrerettlovens bestemmelser 7. Angrerett innebærer at kjøperen uten grunn kan returnere produktet og få pengene refundert dersom koden ikke er brukt. Hvis koden er brukt, vil det da derfor ikke være mulig å få refundert beløpet.</p>
                <p className='mb-6'>Du som kjøper står selv ansvarlig på å melde ifra til oss innen 14 dager dersom du ikke får bruk for koden og vil ha beløpet refundert. Derfor oppfordrer vi å ta kontakt så fort det er mulig ved ønske om refundering.</p>

                <h2 className='font-semibold text-xl mb-4'>Reklamasjon ved mangler og frist for å melde krav ved forsinkelse</h2>
                <p className='mb-2'>Dersom det oppstår problemer underveis i spillet, og- eller finner feil og mangler underveis oppfordrer vi dere til å ta kontakt med oss så fort som mulig for at vi kan rette opp i feilen. Dersom feilen ødelegger for produktet du har kjøpt vil du få refundert beløpet eller om du vil ha en ny kode og starte om igjen.</p>
                <p className="mb-6">Ved utført betaling vil du som kjøper motta en e-post fra oss med kode til å starte spillet. Koden er en engangskode som kun fungerer 1 gang. Denne mailen skal ankomme innen 10 minutter. Tar det lengre enn 10 minutter å motta e-posten fra oss, send oss en mail så får vi sendt deg en ny. Vennligst sjekk søppelposten og spam mappen før du melder ifra. E-posten fra oss kan ved få tilfeller havne i søppelposten eller under spam mappen.</p>

                <h2 className='font-semibold text-xl mb-4'>Kjøperens rettigheter ved forsinkelse</h2>
                <p className='mb-2'>Dersom e-posten fra oss ikke kommer innen 10 minutter etter utført betaling vil du som kjøper få valget om å få kjøpet refundert, nytt produkt eller ny kode.</p>
                <p className="mb-6">Ta kontakt med oss på e-post: <a href="mailto:support@escapecitygame.no" className='text-blue-500 underline'>support@escapecitygame.no</a>. ved forsinkelse.</p>

                <h2 className='font-semibold text-xl mb-4'>Personopplysninger</h2>
                <p className='mb-2'>Med mindre kjøperen samtykker til noe annet, kan selgeren kun innhente og lagre de personopplysningene som er nødvendig for at selgeren skal kunne gjennomføre forpliktelsene etter avtalen. Kjøperens personopplysninger skal kun utleveres til andre hvis det er nødvendig for at selgeren skal få gjennomført avtalen med kjøperen, eller i lovbestemt tilfelle.</p>
                <p className='mb-2'>Selgeren kan kun innhente kjøperens personnummer dersom det er saklig behov for sikker identifisering og slik innhenting er nødvendig.</p>
                <p className='mb-2'>Hvor vi som selger vil benytte kjøperens personopplysninger til andre formål, for eksempel til å sende kjøperen reklame eller informasjon ut over det som er nødvendig for å få gjennomført avtalen, må selgeren innhente kjøperens samtykke ved avtaleinngåelsen.</p>
                <p className='mb-2'>Selgeren må gi kjøperen informasjon om hva personopplysningene skal brukes til og om hvem som skal bruke de. Kjøperens samtykke må være frivillig og avgis ved en aktiv handling, for eksempel avkrysning.</p>
                <p className="mb-6">Dersom kjøper har spørsmål til hva vi kan bruke personopplysningene til eller om kjøper ønsker sine personopplysninger slettet etter at produktet er ferdig, kan kjøper enkelt ta kontakt med oss på e-post: <a href="mailto:support@escapecitygame.no" className='text-blue-500 underline'>support@escapecitygame.no</a>.</p>
            </div>
        </>
    )
}

export default Vilkår