import React, { useEffect, useState } from 'react';

const QuizCompontent = ({ title, buildup, question, answer, alternatives, afterQuestion, questionId, handleQuizAnswer, handleClose, setAfterQuestionModalText }) => {
    const [userAnswer, setUserAnswer] = useState('');
    const [feedback, setFeedback] = useState('');
    const [shuffledOptions, setShuffledOptions] = useState([]);

    useEffect(() => {
        shuffleAndSetOptions();
    }, [questionId]);

    const shuffleAndSetOptions = () => {
        const parsedAlternatives = JSON.parse(alternatives);
        const options = [answer, ...parsedAlternatives];
        setShuffledOptions(shuffleArray(options));
    };

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const handleAnswerClick = (selectedOption) => {
        setUserAnswer(selectedOption);
        const isCorrect = selectedOption === answer;
        setFeedback(isCorrect ? 'Congrats! Right answer' : `Wrong. The answer is ${answer}`);
        handleQuizAnswer(questionId, isCorrect);
        setAfterQuestionModalText(afterQuestion);
    };

    const formattedBuildup = buildup.replace(/\/n/g, '<br />');

    return (
        <div className='fixed overflow-scroll z-[999999] bg-white/90 w-full h-full p-8'>
            <h1 className='text-2xl font-bold'>{title}</h1>
            {/* <p className='mt-4 text-lg'>{buildup}</p> */}
            <div className='mt-4 text-lg' dangerouslySetInnerHTML={{ __html: formattedBuildup }}></div>
            <p className='mt-4 text-lg'>{question}</p>
            <div className='space-y-4 mt-6'>
                {shuffledOptions.map((option, index) => (
                    <button
                        key={index}
                        className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded text-lg'
                        onClick={() => handleAnswerClick(option)}
                        disabled={!!userAnswer}
                    >
                        {option}
                    </button>
                ))}
            </div>
            {feedback && <p className="mt-4 text-xl">{feedback}</p>}
            {/* <button
                className='mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                onClick={handleCloseWithAfterQuestion}
            >
                Lukk
            </button> */}
        </div>
    )
}

export default QuizCompontent