import React, { useEffect } from 'react'

const Questions = ({ numberOfQuestions, numberOfAnswered, setEndScreen, location }) => {
    useEffect(() => {
        if (numberOfQuestions > 0 && numberOfAnswered === numberOfQuestions) {
            setEndScreen(true);
        }
    }, [numberOfAnswered, numberOfQuestions, setEndScreen]);

    return (
        <div className='fixed top-5 right-5 z-[1000] bg-white p-3 rounded-md shadow'>
            <p className='text-lg font-bold'>{numberOfAnswered}/{numberOfQuestions} {location.includes('eng') ? 'questions' : 'spørsmål'}</p>
        </div>
    )
}

export default Questions