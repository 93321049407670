import React, { useState } from 'react';

const Winner = ({ code, correctAnswers, completionTime, location }) => {
    const [teamName, setTeamName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const finishData = {
            code,
            name: teamName,
            email,
            time: completionTime,
            correct: correctAnswers,
            date: new Date().toISOString(),
        };

        try {
            const response = await fetch('https://escapecitygames.pellenilsen.no/api/save-finish', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(finishData),
            });

            if (response.ok) {
                setMessage(location.includes('eng') ? 'Your details are saved and uploaded to the leaderboard.' : 'Detaljene dine er lagret og lastet opp på resultatlisten.');
                console.table(finishData);
            } else {
                setMessage(location.includes('eng') ? 'Failed to save your details. Please try again.' : 'Feilet med å lagre detaljene. Vennligst prøv igjen.');
            }
        } catch (error) {
            setMessage('');
            setMessage(location.includes('eng') ? 'An error occurred. Please try again.' : 'En feil skjedde. Vennligst prøv igjen.');
        }
    }

    return (
        <div className="fixed overflow-scroll z-[9999] bg-white/90 w-full h-full p-8">
            <h1 className="text-2xl font-bold">{location.includes('eng') ? 'You did it!' : 'Dere greide det!'}</h1>

            <p className="mt-4">
                
                {location.includes('eng') ? 'You found the exit, and helped Christian Hals escape the city!' : 'Dere har funnet utgangen, og hjulpet Christian Hals til å rømme fra byen!'}
            </p>

            <p className="mt-4">
                
                {location.includes('eng') ? 'Hals will never forget how much you did for him today, and really appreciates your hard work, and thanks you for completing the game.' : 'Hals vil aldri glemme alt dere gjorde for han idag, og setter stor pris på at dere fullførte dette spillet.'}
            </p>

            <form onSubmit={handleSubmit} className="mt-6">
                <div className="mb-4">
                    <label className="block text-lg font-bold mb-2" htmlFor="teamName">
                        {location.includes('eng') ? 'Team name' : 'Lag navn'}
                    </label>
                    <input
                        type="text"
                        id="teamName"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded text-lg mb-6"
                >
                    Save
                </button>
                {message && <p className="mt-4 text-lg mb-6">{message}</p>}
            </form>

            <a href='https://escapecitygame.no' className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded text-lg mt-6 text-center">
                {location.includes('eng') ? 'End game' : 'Avslutt spill'}
            </a>
        </div>
    );
};

export default Winner;
