import React from 'react'

const Notification = ({ text, setNotificationText, setBackpackVisible, location }) => {
    const handleClick = () => {
        setNotificationText('');
        setBackpackVisible(true);
    };
    
    return (
        <>
            {text.length > 0 && (
                <div className='fixed top-1/2 left-[10%] right-[10%] z-[1000] bg-white border-4 border-blue-500 p-3 rounded-md shadow' onClick={handleClick}>
                    <p className='text-lg font-bold'>{text}</p>
                    <p className='mt-2 text-center italic text-sm'>{location.includes('eng') ? 'Click to close' : 'Klikk for å lukke'}</p>
                </div>
            )}
        </>
    )
}

export default Notification