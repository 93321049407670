import React, { useState, useEffect } from 'react';

const Timer = ({ dateUsed, setTimerRanOut }) => {
    const [timeLeft, setTimeLeft] = useState('Laster...');
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const endTime = new Date(new Date(dateUsed).getTime() + 2 * 60 * 60 * 1000); // 2 hours in milliseconds
            const now = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
            const difference = endTime.getTime() - new Date(now).getTime();

            // console.log('dateUsed:', new Date(dateUsed).toISOString());
            // console.log('endTime:', endTime.toISOString());
            // console.log('now:', now);
            // console.log('difference:', difference);

            if (difference > 0) {
                let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                let minutes = Math.floor((difference / 1000 / 60) % 60);
                let seconds = Math.floor((difference / 1000) % 60);

                hours = hours < 10 ? '0' + hours : hours;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                return `${hours}:${minutes}:${seconds}`;
            } else {
                setIsComplete(true);
                setTimerRanOut(true);
                return '00:00:00';
            }
        };

        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            if (newTimeLeft === '00:00:00') clearInterval(timer);
        }, 1000);

        return () => clearInterval(timer);
    }, [dateUsed, setTimerRanOut]);

    return (
        <div className='fixed top-20 right-5 z-[1000] bg-white p-3 rounded-md shadow'>
            {!isComplete ? (
                <p className='text-lg font-bold'>{timeLeft}</p>
            ) : (
                <p className='text-lg font-bold'>Tiden er over!</p>
            )}
        </div>
    )
}

export default Timer